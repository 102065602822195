import request from '@/utils/request'

export function queryBasicInfo(idserial, projectId) {
  return request({
    url: 'pay/web/eCardRecharge/queryBasicInfo/' + idserial + '/' + projectId,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      return data
    }]
  })
}

export function createOrder(data) {
  return request({
    url: 'pay/web/eCardRecharge/createOrder',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: data
  })
}
