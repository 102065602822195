<template>
  <div id="commonPay">
    <div>
      <!-- 顶部NavBar 开始 -->
      <div class="head">
        <van-nav-bar
          title="校园一卡通"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div class="outboxstyle">
      <!-- 表单面板 -->
      <div class="boxstyle">
        <div class="title">
          <van-icon name="card" />
          <span>校园卡信息</span>
        </div>
        <div class="box-form">
          <van-field
            readonly
            clickable
            name="picker"
            :value="form.card"
            input-align="right"
            label="账户类型"
            placeholder="点击选择缴费卡"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-cell title="姓名" :value="form.name" />
          <van-cell title="学号" :value="form.num" />
          <van-cell title="余额" :value="form.balance" />
        </div>
      </div>
      <div class="boxstyle">
        <div class="title">
          <van-icon name="bill" />
          <span>缴费金额</span>
        </div>
        <div class="check-box">
          <div class="position-rel" :class="{'checked':form.chooseRadio==50}" @click="chooseRadio(50)">
            ￥50
            <img v-if="form.chooseRadio==50" src="@/assets/newImages/right.png">
          </div>
          <div class="position-rel" :class="{'checked':form.chooseRadio==100}" @click="chooseRadio(100)">
            ￥100
            <img v-if="form.chooseRadio==100" src="@/assets/newImages/right.png">
          </div>
          <div class="position-rel" :class="{'checked':form.chooseRadio==200}" @click="chooseRadio(200)">
            ￥200
            <img v-if="form.chooseRadio==200" src="@/assets/newImages/right.png">
          </div>
        </div>
        <div class="position-rel width-100" :class="{'checked':form.custom}">
          <!-- <input type="text"> -->
          <van-field v-model="form.custom" label-width="120" :class="{'checked2':form.custom}" placeholder="自定义金额" type="number" input-align="center" @focus="clearRadio" @blur="strJoin" />
          <img v-if="form.custom" src="@/assets/newImages/right.png">
        </div>
        <div class="font-color">充值成功后请及时线下领款</div>
      </div>
    </div>
    <div class="bottom">
      <van-button type="info" size="large" :loading="btnLoading" class="bottombutton" @click="next">{{ $t('main.next') }}</van-button>
    </div>
  </div>
</template>

<script>
import { queryBasicInfo, createOrder } from '@/api/charge'
import { Toast } from 'vant'
import { fenToYuan } from '@/utils/commonUtils'
export default {
  name: 'CommonPay',
  data() {
    return {
      btnLoading: false, // 按钮loading状态
      showPicker: false, // 选择卡
      resList: {}, // res
      columns: [], // 卡下拉
      form: {
        card: '', // 证件号
        cardId: '',
        name: '--', // 姓名
        num: '--', // 学号
        balance: '--', // 余额
        chooseRadio: '', // 单选金额
        custom: '' // 自定义金额
      }
    }
  },
  watch: {
    form: {
      handler: 'inputCustom',
      deep: true
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    this.queryBasicInfo()
  },
  methods: {
    // 获取卡下拉
    queryBasicInfo() {
      queryBasicInfo(this.$store.getters.idserial, this.$route.query.projectId).then(res => {
        this.resList = res.data
        this.form.name = res.data.username
        this.form.num = res.data.idserial
        this.getNewList()
      })
    },
    // 提交下一步
    createOrder() {
      // // 校验
      if (this.form.card === '') {
        Toast.fail('请选择账户类型')
        return false
      }
      if (this.form.chooseRadio === '' && this.form.custom === '') {
        Toast.fail('请输入缴费金额')
        return false
      }
      this.btnLoading = true
      var data = {}
      data.schoolCode = window.localStorage.getItem('schoolcode')
      data.projectId = this.$route.query.projectId
      data.cardId = this.form.cardId
      data.cardType = this.form.card
      data.payamtStr = this.form.chooseRadio || this.form.custom
      createOrder(data).then(res => {
        var oid = res.data.PayOrderTrade.id
        this.$router.push({ path: '/person', query: { 'projectId': this.$route.query.projectId, 'orderId': oid }})
      }).catch(() => {
        this.btnLoading = false
      }
      )
    },
    // 把data改造成一维数组
    getNewList() {
      this.columns = []
      this.resList.cardlist.forEach(item => {
        this.columns.push(item.acctype)
      })
    },
    // 选中卡
    onConfirm(value) {
      this.form.card = value
      // 赋值余额
      this.resList.cardlist.forEach(item => {
        if (item.acctype === this.form.card) {
          this.form.balance = fenToYuan(item.cardbal)
          this.form.cardId = item.cardid
        }
      })
      this.showPicker = false
    },
    // 顶部返回
    onClickLeft() {
      this.$router.push({ path: '/home' })
    },
    // 选择默认金额
    chooseRadio(num) {
      this.form.chooseRadio = num
      this.form.custom = ''
    },
    // 输入自定义金额
    inputCustom(val) {
      if (val.custom !== '') {
        this.form.chooseRadio = ''
      }
    },
    // 自定义金额失去焦点拼接￥
    strJoin() {
      if (this.form.custom) {
        this.form.custom = parseFloat(this.form.custom)
      }
    },
    // 自定义金额获取焦点清除预选金额
    clearRadio() {
      this.form.custom = ''
      this.form.chooseRadio = ''
    },
    // 提交表单
    next() {
      this.createOrder()
    }
  }
}
</script>
<style scoped lang="scss">
#commonPay {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 100px;
    width: 100%;
    background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
  .title{
    color: #2F9FF2;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span{
      color: #000;
      font-size: 14px;
      margin: 2px 5px 0;
    }
  }
  .check-box{
    display: flex;
    justify-content: space-between;
  }
  .position-rel{
    width: 85px;
    height: 40px;
    font-size: 14px;
    color: #D0D0D0;
    text-align: center;
    line-height: 40px;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    position: relative;
    img{
      position: absolute;
      right: 0px;
      top: 0px;
      width: 20px;
    }
    ::after{
      border: 0;
    }
  }
  .checked{
    border: 1px solid #2F9FF2;
    color: #F86262;
  }
  .checked2{
    ::v-deep input{
      color: #F86262;
    }
  }
  .width-100{
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    .van-cell{
      padding: 0;
    }
  }
}
.boxstyle {
  padding: 20px;
  margin: 10px 15px 10px 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 80px;
  z-index: 99;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell{
  padding-left: 0;
  padding-right: 0;
}
.van-hairline--bottom.van-hairline--bottom::after{
  border: 0;
}
.van-cell:not(:last-child)::after{
  left: 0;
}
.font-color{
  font-size: 12px;
  color: red;
  text-align: center;
  margin-top: 10px;
}
</style>
<style lang="scss">
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
.van-nav-bar {
  height: 80px;
}
</style>
